<template>
  <div>
    <!-- Modal Trigger -->
    <a class="btn wide-btn modal-trigger" :href="'#' + modalId">Skip signature</a>

    <!-- Modal Structure -->
    <div :id="modalId" class="modal">
      <div class="modal-content">
        <section class="nonEsign-form mt-10"> 
          <center>
            <h3>Skip E-signature</h3>
            <span class='red-text bold-text'><i class="fas fa-exclamation-triangle red-text"></i> WARNING!</span><br> 
            I understand the higher risk of no signature transaction
            <br>
          </center>
          <div class="row">
            <div class="col s6">
              <label>Cardholder First name</label>
              <input 
                v-model='firstName'
                autocomplete="first_name"
                v-validate="'required'"
                name='firstName'>
              <span class='red-text small-font' v-show='errors.first("email")'>{{errors.first("firstName")}}</span>
            </div>
            <div class="col s6">
              <label>Cardholder Last name</label>
              <input 
                v-model='lastName'
                autocomplete="last_name"
                v-validate="'required'"
                name='lastName'>
              <span class='red-text small-font' v-show='errors.first("email")'>{{errors.first("lastName")}}</span>
            </div>
          </div>
          
          <div class="row">
            <div class="col s6">
              <label>Staff First name</label>
              <input 
                v-model='staffFirstName'
                autocomplete="first_name"
                v-validate="'required'"
                name='staffFirstName'>
              <span class='red-text small-font' v-show='errors.first("email")'>{{errors.first("firstName")}}</span>
            </div>

            <div class="col s6">
              <label>Staff Last name</label>
              <input 
                v-model='staffLastName'
                autocomplete="last_name"
                v-validate="'required'"
                name='staffLastName'>
              <span class='red-text small-font' v-show='errors.first("email")'>{{errors.first("lastName")}}</span>
            </div>
          </div>
        </section>
      </div>
      <div class="modal-footer">
        <button class="btn" @click="submit" :disabled="disabled">
          <span v-if="disabled">
            <i class="fas fa-spinner fa-spin grey-text"></i> 
            Processing ...
          </span>
          <span v-else>Skip signature</span>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    props: ["transactionId"],
    data() {
      return {
        disabled: false,
        firstName: null,
        lastName: null,
        staffFirstName: null,
        staffLastName: null,
      }
    },
    methods: {
      submit() {
        this.$validator.validateAll().then((result) => {
          if (result) {
            this.disabled = true
            this.$http.post(this.apiPath, this.params).then(response => {
              window.location.href = "/"
            }, error => {
              this.disabled = false
              this.errorHandler(error)
            })
          } else {
            this.notifyError("Please complete all required fields!")
          }
        })
      }
    },
    computed: {
      params() {
        return {
          first_name: this.firstName,
          last_name: this.lastName,
          staff_first_name: this.staffFirstName,
          staff_last_name: this.staffLastName,
        }
      },
      apiPath() {
        return  "/api/v1/app/merchant/manual_charges/" + this.transactionId + "/override"
      },
      modalId() {
        return "override_" + this.transactionId
      }
    }
  }
</script>
