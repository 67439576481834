<template>
  <div>
    <div class="row">
      <div class="col s12 m6">

        <div class="row">
          <div class="input-field col s12">
            <i class="fas fa-dollar-sign prefix grey-text"></i>
            <input 
              name="amount"
              id="amount"
              autofocus
              v-model='amount'
              step="0.01"
              type="number" 
              inputmode="decimal"
              class='inputText'
              lang="en-001">
              <span class='red-text small-font' v-show='errors.first("amount")'>{{errors.first("amount")}}</span>

            <label for="amount">Amount</label>
          </div>
          <div class="input-field col s12">
            <i class="fas fa-sticky-note prefix grey-text"></i>
            <input name="note" id="note" type="text" v-model="note" ref="note" maxlength="50">

            <label for="note">Note (Optional)</label>
          </div>

          <div class="input-field col s12">
            <i class="fas fa-file-invoice prefix grey-text"></i>
            <input name="invoice" id="invoice" type="text" v-model="invoice" ref="invoice" maxlength="50">

            <label for="invoice">{{invoiceLabel}}</label>
          </div>

          <div class="input-field col s12" v-if="requireInvoiceConfirmation">
            <i class="fas fa-file-invoice prefix grey-text"></i>
            <input name="invoice-cfm" v-model="invoiceCfm" id="invoice-cfm" type="text" v-validate="{required: invoice && invoice.length > 0, confirmed: 'invoice'}" maxlength="50">

            <label for="invoice-cfm">Invoice (confirmation)</label>
            <span v-show="errors.has('invoice-cfm')" class="red-text">Invoice numbers do not match</span>
          </div>
        </div>
        <div v-if="!processing">
          <button class="btn" @click="addItem">Add Item</button><br><br>
          <button class="btn" @click="close" v-if="showCheckout">
            checkout 

            <i class="fas fa-arrow-right"></i>
          </button>
        </div>
        <div v-else>
          <i class="fas fa-spinner fa-spin grey-text"></i> 
          Adding {{amount | currency}} item ...
        </div>
      </div>
      <div class="col s12 m6">
        <div class="card-panel">
          <show-items :items="items" :items-updated="itemsUpdated"></show-items>
          <div class="bold-font big-font emerald right">{{calculatedTotalAmount | currency}}</div>
          <br><br>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import showItems from './show_items'

  export default {
    mounted() {
      M.updateTextFields()
    },
    components: {showItems},
    props: {
      items: {
        type: Array,
        default: () => []
      },
      itemsUpdated: {
        type: Function,
        default: () => {}
      },
      setAmount: {
        default: null
      },
      setNote: {
        default: ""
      },
      setInvoice: {
        default: ""
      },
      invoiceLabel: {
        default: 'Invoice'
      },
      requireInvoiceConfirmation: {
        type: Boolean,
        default: false
      },
      showCheckout: {
        type: Boolean,
        default: true
      },
    },
    data() {
      return {
        amount: this.setAmount || null,
        note: this.setNote || "",
        invoice: this.setInvoice || "",
        invoiceCfm: "",
        processing: false
      }
    },
    methods: {
      close() {
        this.$emit('close')
      },
      addItem() {
        this.$validator.validateAll().then((result) => {
          if (result) {
            this.processing = true
            var params = {
              amount: parseFloat(this.amount),
              invoice: this.invoice,
              note: this.note,
              optional_phone_validation: true
            }

            this.$http.post("/api/v1/app/merchant/manual_charges/validate_params", params).then(response => {
              this.items.push(
                {
                  amount: parseFloat(this.amount),
                  invoice: this.invoice,
                  note: this.note
                }
              )
              this.amount = null
              this.note = ""
              this.invoice = ""
              this.invoiceCfm = ""
              this.itemsUpdated(this.calculatedTotalAmount)
              this.$validator.reset()
              this.processing = false
            }, error => {
              this.errorHandler(error)
              this.processing = false
            })
          } else {
            this.showPending = false
            this.notifyError("Please complete all required fields!")
          }
        })
      }
    },
    computed: {
      calculatedTotalAmount() {
        return this.items.reduce((total, item) => total + item.amount, 0)
      }
    },
    watch: {
      setAmount(newAmount) {
        this.amount = newAmount;
      },
    }
  }
</script>
