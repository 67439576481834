<template>
  <div class='container'>
    <div v-if='showForm && !showLocationConfirmation && !showPending && !showItemForm'>
      <div class='row'>
        <div class="col s12 m6">
          <label>Email or Phone</label>
          <input v-model='contact'>
        </div>

        <div class='col s12 m6'>
          <div v-if="!controledByItems"> 
            <label>Amount, USD</label>
            <input 
              name='amount'
              inputmode="decimal"
              v-model='amount'
              step="0.01"
              type="number"
              pattern="\d*"
              v-validate="'min_value:0|required'"
              class='inputText'
              lang="en-001">

            <span class='red-text small-font' v-show='errors.first("amount")'>The Amount field must be greater than 0.5</span>
          </div>

          <div v-else>
            <span class="items-amount bold-font emerald">{{amount | currency}}</span>
          </div>
        </div>

        <div v-if="!controledByItems">
          <div class="col s12 m6">
            <label>{{invoiceLabel}}</label>
            <input v-model="invoice" name="invoice" type="text" ref="invoice">
          </div>

          <div class="input-field col s12 m6" v-if="requireInvoiceConfirmation">
            <i class="fas fa-file-invoice prefix grey-text"></i>
            <input name="invoice-cfm" id="invoice-cfm" type="text" v-validate="{required: invoice.length > 0, confirmed: 'invoice'}">

            <label for="invoice-cfm">Invoice (confirmation)</label>
            <span v-show="errors.has('invoice-cfm')" class="red-text">Invoice numbers do not match</span>
          </div>

          <div class="col s12 m6">
            <label>Note (will be shared with the client)</label>
            <input v-model="note" name="note">
          </div>
        </div>
      </div>

      <div v-show="controledByItems">
        <div class="row">
          <div class="col s12">
            <show-items :items="items" :items-updated="itemsUpdated"></show-items>
          </div>
        </div>
      </div>

      <div class='row'>
        <div class="col s12">
          <div class="file-field input-field">
            <div class="btn-small outlined-btn">
              <span>Attach PDF</span>
              <input type="file" @change="assignFile" ref="file" placeholder="optional">
            </div>
            <div class="file-path-wrapper">
              <input class="file-path validate" v-model="attachmentName" type="text" placeholder="(optional)">
            </div>
          </div>
        </div>
      </div>

      <button class="btn btn-small outlined-btn right" @click="showItemForm=true" v-show='!showPending'>
        <i class="fas fa-plus"></i>

        Add Item
      </button>

      <br><br>

      <div class='row'>
        <div class='col s6 m3 mt-10' v-show="canReceiveCc" width="100px">
          <label>Allow Credit Card</label>
          <div class="switch">
            <label>
              Off
              <input type="checkbox" v-model="cc">
              <span class="lever"></span>
              On
            </label>
          </div>
        </div>

        <div class='col s6 m3 mt-10' v-show="canReceiveCheck">
          <label>Allow Check</label>
          <div class="switch">
            <label>
              Off
              <input type="checkbox" v-model="check">
              <span class="lever"></span>
              On
            </label>
          </div>
        </div>

        <div class='col s6 m3 mt-10' v-show="false && canReceivePaypal" width="100px">
          <label>Allow PayPal</label>
          <div class="switch">
            <label>
              Off
              <input type="checkbox" v-model="paypal">
              <span class="lever"></span>
              On
            </label>
          </div>
        </div>

        <div class='col s6 m3 mt-10' v-show="canReceiveKlarna" width="100px">
          <label>Allow Klarna pay</label>
          <div class="switch">
            <label>
              Off
              <input type="checkbox" v-model="klarna">
              <span class="lever"></span>
              On
            </label>
          </div>
        </div>

        <div class="col s6 m3 mt-10" v-show="tipSettings">
          <label class="grey-text">Allow tipping</label>
          <div class="switch">
            <label>
              Off
              <input type="checkbox" v-model="tips">
              <span class="lever"></span>
              On
            </label>
          </div>
        </div>

        <div class="col s6 m3 mt-10" v-show="showFeedbackHub">
          <label class="grey-text">Allow Votes/Feedback</label>
          <div class="switch">
            <label>
              Off
              <input type="checkbox" v-model="showFeedbackHubSetting">
              <span class="lever"></span>
              On
            </label>
          </div>
        </div>
      </div>

      <button @click="checkLocationBeforeSendLink" class="btn-large wide-btn mt-25" :disabled="disableSubmitBtn">
        <i class="far fa-paper-plane"></i>
        <span class='ml-10'>Send a bill</span>
      </button>
      <label>Your maximum per transaction limit is $10,000.00. For bills over $10,000.00, please break the amount down into multiple transactions</label>
    </div>
    <div v-if="!showForm && !showLocationConfirmation" class='container mt-25 center'>

      <i class="fas fa-check-circle fa-2x emerald"></i><br>
      <h3>Payment link has<br>been sent</h3>
      <div class="red-text">Payment has NOT been received yet</div>
      <div class='mt-50'>
        <button class='btn-large wide-btn outlined-btn rounded-btn mt-10'
          v-clipboard:success="onCopy"
          v-clipboard:error="onError"
          v-clipboard:copy="link">

          COPY LINK TO CLIPBOARD
        </button>
        <a href='/' class='btn-large wide-btn rounded-btn mt-10'>Done</a>
      </div>
    </div>

    <!-- Show location confirmation screen E-Sign Flow-->
    <section v-show="showLocationConfirmation && !showPending">
      <h3 class="emerald">
        Are you sure that this transaction is for {{groupLocation}}?
      </h3>

      <div class="mt-25">
        <button class="btn btn-large outlined-btn" @click="showLocationConfirmation=false">No</button>
        <button class="btn btn-large" style="margin-left: 20px;" @click="sendLink">Yes</button>
      </div>
    </section>

    <center v-if='showPending' class='grey-text mt-25'>
      <i class="fas fa-spinner fa-spin"></i> Processing...
    </center>

    <items
      v-if="showItemForm"
      :items="items"
      :items-updated="itemsUpdated"
      :set-invoice="invoice"
      :set-note="note"
      :set-amount="items.length > 0 ? null : amount"
      :invoice-label="invoiceLabel"
      :require-invoice-confirmation="requireInvoiceConfirmation"
      @close="showItemForm=false">

    </items>
  </div>
</template>
<script>
  import items from './generic_cards/items'
  import showItems from './generic_cards/show_items'

  export default {
    props: ["canReceiveAch", "canReceiveCc", "canReceiveCheck", "canReceiveKlarna", "canReceivePaypal", "tipSettings", "requireInvoiceField", "requireInvoiceConfirmation", "groupsEnabled", "groupLocation", "showFeedbackHub"],
    components: {items, showItems},
    data() {
      return {
        contact: null,
        amount: null,
        note: "",
        attachment: null,
        attachmentName: "",
        invoice: "",
        ach: false,
        cc: this.canReceiveCc,
        klarna: false,
        paypal: false,
        check: false,
        tips: this.tipSettings,
        showPending: false,
        disableSubmitBtn: false,
        showForm: true,
        linkOrderId: null,
        showLocationConfirmation: false,
        items: [],
        showItemForm: false,
        showFeedbackHubSetting: true
      }
    },
    methods: {
      assignFile() {
        this.attachment = this.$refs.file.files[0]
        this.attachmentName = event.target.files[0].name
      },
      checkLocationBeforeSendLink() {
        // If conjunction groups enabled we will show extra confirmation window
        // to makes sure we're submitting to the correct location
        if(this.groupsEnabled) {
          this.showLocationConfirmation = true
        } else {
          this.sendLink()
        }
      },
      sendLink() {
        this.$validator.validateAll().then((result) => {
          if (result) {
            this.disableSubmitBtn = true
            this.showLocationConfirmation = false
            this.showPending = true
            const headers = { 'Content-Type': 'multipart/form-data' };
            this.$http.post('/api/v1/app/merchant/link_orders', this.formData, {headers: headers}).then(response => {
              this.showForm = false
              this.showPending = false
              this.linkOrderId = response.body.link_order.id
            }, error => {
              this.disableSubmitBtn = false
              this.showPending = false
              this.errorHandler(error)
            })
          } else {
            this.notifyError("Please complete all required fields!")
            this.disableSubmitBtn = false
            this.showPending = false
          }
        })
      },
      onCopy() {
        this.notifySuccess('Copied to clipboard!')
      },
      onError() {
        this.notifyError('Unable to copy')
      },
      itemsUpdated(amount) {
        this.amount = amount
        this.invoice = ""
        this.note = ""
      }
    },
    computed: {
      controledByItems() {
        return this.items.length > 0 ? true : false
      },
      formData() {
        let formData = new FormData()

        formData.append("amount", this.amount)
        formData.append("note", this.note)
        formData.append("invoice", this.invoice)
        formData.append("contact", this.contact)
        formData.append("tips", this.tips)
        formData.append("show_feedback_hub", this.showFeedbackHubSetting)
        this.items.forEach((item, index) => {
          formData.append(`items[]`, JSON.stringify(item));
        });
        if(this.attachment) {
          formData.append("attachment", this.attachment)
        }

        let paymentAdded = false;

        if (this.ach) {
            formData.append("allowed_payments[]", "ach");
            paymentAdded = true;
        }
        if (this.cc) {
            formData.append("allowed_payments[]", "cc");
            paymentAdded = true;
        }
        if (this.check) {
            formData.append("allowed_payments[]", "check");
            paymentAdded = true;
        }
        if (this.klarna) {
            formData.append("allowed_payments[]", "klarna");
            paymentAdded = true;
        }
        if (this.paypal) {
            formData.append("allowed_payments[]", "paypal");
            paymentAdded = true;
        }

        if (!paymentAdded) {
            formData.append("allowed_payments[]", []);
        }

        return formData
      },
      link() {
        return "https://app.iwallet.com/client/l/" + this.linkOrderId
      },
      invoiceLabel() {
        return this.requireInvoiceField ? "Invoice # (required)" : "Invoice # (optional)" 
      }
    }
  }
</script>
<style scoped>
  .items-amount {
    font-size: 3em !important;
  }
</style>
