<template>
  <div class='center'>
    <h3 class='mt-50'>Waiting for payment approval: {{amount | currency}}</h3>

    <section class="red-text mt-50">
      <div>
        A transaction has NOT been approved yet.
      </div>
      <div class='mt-25'>
        Text message has been sent to cardholder
      </div>
      <div class=''>
        Ask the cardholder to approve the transaction using a link on their
        phone
      </div>

      <br>
      <br>
      <a href="/merchant/manual_charges" class='btn-large wide-btn mt-50'>OK</a>
      <!-- <div class='mt&#45;25'> -->
      <!--   <a :href="resendPath" class='mt&#45;10 btn wide&#45;btn grey lighten&#45;4 grey&#45;text text&#45;darken&#45;3'>RE&#45;SEND SMS</a> -->
      <!-- </div> -->
    </section>
  </div>
</template>
<script>
  export default {
    props: ["amount", "id"],
    computed: {
      resendPath() {
        return "".concat("/merchant/manual_charges/", this.id, "/edit")
      }
    }
  }
</script>
