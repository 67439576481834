<template>
  <div>
    <section v-if="!submitSucceed">
      <form enctype="multipart/form-data" novalidate ref="form">
        <image-uploader
            :debug="0"
            :maxWidth="900"
            :quality="0.8"
            :autoRotate=true
            outputFormat="blob"
            :preview=false
            :className="['file-upload', { 'fileinput--loaded' : hasImage }]"
            :capture="false"
            accept="image/*"
            doNotResize="['gif', 'svg']"
            @input="setImage"
            @onUpload="startImageResize"
            onComplete="endImageResize"
          >

          <label for="fileInput" slot="upload-label">
            <div class="btn-large wide-btn rounded-btn mt-25" v-show="!readyForSubmit">
              <span>
                <i class="fas fa-camera"></i> 
                <span class="upload-caption">{{ hasImage ? $t("sendBill.replace") : $t("sendBill.checkByPhoto") }}</span>
              </span>
            </div>
          </label>
        </image-uploader>

        <div class="center">
          <img :src="src" alt="Check image" class="mt-10 responsive-img" v-show="src" />
        </div>

        <section class="grey-text small-font center" v-show="src && !waitingResponse">
          <div class="red-text text-darken-3 large-font bold-font">
            <i class="fas fa-exclamation-triangle"></i> 
            {{$t("sendBill.pleaseConfirmCheckAmount", {amount: amount})}}
          </div>
          <div class="btn-large wide-btn mt-25" @click="submit">
            <i class="fas fa-check"></i> 
            {{$t("sendBill.confirmCheckAmountAndSubmit", {amount: amount})}}
          </div>
        </section>
      </form>

      <div v-if="waitingResponse" class="center mt-25">
        <i class="fas fa-spinner fa-spin grey-text"></i> {{$t("sendBill.processing")}}
      </div>
    </section>
    <section class="center" v-show="submitSucceed">
      <i class="fas fa-check-circle fa-4x emerald"></i><br>
      <h3>{{$t("sendBill.submittedSuccessfully")}}</h3>

    </section>
  </div>
</template>
<script>
  export default {
    props: [
      'linkOrderId',
      'amount',
      'showVote',
      'showReview',
      'showFeedbackHub',
      'useNpsVote'
    ],
    data() {
      return {
        showCheckBtn: true,
        phone: null,
        src: null,
        hasImage: false,
        image: null,
        submitSucceed: false,
        waitingResponse: false,
        readyForSubmit: false,
        transactionId: null,
        votesUrl: null
      }
    },
    methods: {
      submit() {
        this.waitingResponse = true
        let formData = new FormData(this.$refs.form)
        formData.append("code", this.code)
        formData.append("check", this.image)
        formData.append("link_order_id", this.linkOrderId)

        this.$http.post("/api/v1/app/client/link_orders/check_transactions", formData).then(response => {
          this.transactionId = response.body.payment.data.id
          this.votesUrl = response.body.payment.data.attributes.votes_url
          Turbolinks.visit(
            "/a/thank_you?transaction_id=" + this.transactionId +
            "&url=" + this.votesUrl +
            "&locale=" + this.$i18n.locale +
            "&show_vote=" + this.showVote +
            "&show_review=" + this.showReview +
            "&show_feedback_hub=" + this.showFeedbackHub +
            "&use_nps_vote=" + this.useNpsVote
          )
        }, error => {
          this.waitingResponse = false
          this.readyForSubmit = false
          this.errorHandler(error)
        })
      },
      startImageResize() {
        this.$emit('input', true)
      },
      setImage(image) {
        this.hasImage = true
        this.image = image

        // Prepare image preview
        var reader = new FileReader();
        reader.readAsDataURL(image); 
        reader.onloadend = res => {
          this.src = reader.result;                
        }
        this.readyForSubmit = true
      }
    }
  }
</script>
<style scoped>
  input#fileInput {
    display: none;
  }
</style>
