<template>
  <div>
    <div class="btn-large wide-btn rounded-btn mt-50" @click="pay" v-if="showCCBtn && !showOnePaymentOptionForm">
      <span style='margin-right: 10px'>{{$t("sendBill.payWithCard")}}</span>
    </div>
    <section v-else>
      <div v-show="loadingStripeJS">
        <center class='grey-text mt-5'>
          <i class="fas fa-spinner fa-spin"></i> {{$t("sendBill.loading")}}
        </center>
      </div>

      <div class="col s12 mt-10" v-show="!loadingStripeJS">
        <tips-block v-bind="$props"></tips-block>

        <section class="card-container mt-25" v-show="(allowPayByCardInput && !cardToken.token) || (resubmitFlow && !showPending)">
          <center><label>{{$t("sendBill.fillInCardDetails")}}:</label></center>
          <card-element :qid="merchantQid" v-model="cardToken" class="mt-25"></card-element>

          <span class='red-text'>{{errorMessage}}</span>
        </section>

        <!-- Card token -->
        <center>
          <div class='mt-10 signature' v-if="allowPayByCardInput && !errorMessage && cardToken.token && !resubmitFlow">
            <signature-pad @onBegin='onBegin' :policy-url="policyUrl" :allow-sign-autosubmit="allowSignAutosubmit"></signature-pad>
          </div>
        </center>

        <submit-button v-if="cardToken.token || resubmitFlow" v-bind="$props"></submit-button>
        <apple-google-pay v-if="canAppleGooglePay" v-bind="$props"></apple-google-pay>
      </div>
    </section>

    <center v-if='showPending' class='grey-text mt-25'>
      <i class="fas fa-spinner fa-spin"></i> {{$t("sendBill.processing")}}
    </center>
  </div>
</template>
<script>
  import { useCardsBillStore } from "./cc/store"
  import { toRefs } from '@vue/composition-api'

  import tipsBlock from "./cc/tips_block"
  import submitButton from "./cc/submit_button"
  import appleGooglePay from "./cc/apple_google_pay"
  import cardElement from '../../merchant/generic_cards/public_card_element'
  import signaturePad from "./cc/signature_pad"

  export default {
    setup: () => ({ ...toRefs(useCardsBillStore()) }),
    components: { signaturePad, tipsBlock, cardElement, submitButton, appleGooglePay },
    props: [
      'stripePublishableKey',
      'linkOrderId',
      'merchantName',
      'showTips',
      'policyUrl',
      'merchantVotesUrl',
      'canAppleGooglePay',
      'merchantStripeId',
      'showOnePaymentOptionForm',
      'showVote',
      'showReview',
      'showFeedbackHub',
      'useNpsVote'
    ],
    methods: {
      pay() {
        this.$emit('input', true)
        this.showCCBtn = false
        if(!this.showTips) {
          this.updateAmounts()
        }
      },
      onBegin() {
        this.disableSubmit = false
      }
    },
    watch: {
      "cardToken.token": function(newVal, oldVal) {
        this.updateAmounts();
      }
    }
  }
</script>
<style scoped>
  .card-input {
    border: 2px solid #00ab8e;
  }
  .card-container {
    margin-left: 3px;
    margin-right: 3px;
  }
  .signature {
    width: 100%;
  }
</style>
