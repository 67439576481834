<template>
  <div>
    <div v-if='isProcessible'>
      <center>
        <h3 class="bold-font mt-5">{{$t("sendBill.payment")}}: {{amount}}</h3>

        <div v-show="!cardToken">
          <div class='large-font mt-5'>
            {{$t("sendBill.to")}}: {{merchantName}}
          </div>

          <div class="grey-text" v-if="invoice">Invoice: {{invoice}}</div>
        </div>
      </center>

      <div v-if="allowed('cc') && available('cc') && genericFlow">
        <cc-generic-pay
          v-model='selectedCC'
          v-bind="$props"
          :show-one-payment-option-form="showOnePaymentOptionForm()"
          :can-apple-google-pay="available('apple_google_pay')"
          v-show="!(selectedCheck || selectedKlarna || selectedPaypal)">
        </cc-generic-pay>
      </div>

      <div v-if="allowed('cc') && available('cc') && !genericFlow">
        <cc-pay
          v-model='selectedCC'
          v-bind="$props"
          v-show="!(selectedCheck || selectedKlarna || selectedPaypal)">
        </cc-pay>
      </div>

      <div v-if="allowed('klarna') && available('klarna')">
        <klarna-pay
          v-model='selectedKlarna'
          v-show='!(selectedCC || selectedCheck || selectedPaypal)'
          v-bind="$props">
        </klarna-pay>
      </div>

      <div v-if="allowed('check') && available('check')">
        <check-pay
          v-model='selectedCheck'
          v-show='!(selectedCC || selectedKlarna || selectedPaypal)'
          v-bind="$props">
        </check-pay>
      </div>

      <div v-if="allowed('paypal') && available('paypal') && paypalMerchantId">
        <paypal-pay 
          v-model='selectedPaypal'
          v-show='!(selectedCC || selectedCheck || selectedKlarna)'
          v-bind="$props">
        </paypal-pay>
      </div>

      <div 
        v-if="(!allowed('cc') || !available('cc')) &&
        (!allowed('check') || !available('check')) &&
        (!allowed('paypal') || !available('paypal'))"
        class="center red-text">

        {{$t("sendBill.noPaymentMethod")}}
      </div>
    </div>
    <div v-else>
      <h3 class='center red-text'>{{$t("sendBill.couldNotProcess")}}</h3>
    </div>
    <div class="switch center mt-25" v-show="!cardToken">
      {{$t("sendBill.changeLanguage")}}<br>
      <label>
        Español
        <input type="checkbox" @change="changeLocale" v-model="english">
        <span class="lever"></span>
        English
      </label>
    </div>
  </div>
</template>
<script>
  import ccPay from './cc_pay'
  import ccGenericPay from './cc_generic_pay'
  import klarnaPay from './klarna_pay'
  import checkPay from './check_pay'
  import paypalPay from './paypal_pay'
  import { useCardsBillStore } from "./cc/store"
  import { toRefs } from '@vue/composition-api'

  export default {
    setup: () => ({ ...toRefs(useCardsBillStore()) }),
    components: {ccPay, checkPay, ccGenericPay, klarnaPay, paypalPay},
    props: {
      stripePublishableKey: String,
      merchantStripeId: String,
      linkOrderId: String,
      allowedByMerchantPayments: Array,
      availableForMerchantPaymentMethods: Array,
      amount: String,
      amountNumberParam: Number,
      merchantName: String,
      invoice: String,
      merchantQidParam: String,
      state: String,
      showTips: Boolean,
      genericFlow: Boolean,
      policyUrl: String,
      merchantVotesUrl: String,
      allowSignAutosubmitSetting: Boolean,
      showVote: Boolean,
      showReview: Boolean,
      showFeedbackHub: Boolean,
      useNpsVote: Boolean,
      paypalClientId: String,
      paypalMerchantId: String,
      bnCode: String
    },
    created() {
      // Set Store values
      this.allowSignAutosubmit = this.allowSignAutosubmitSetting
      this.merchantQid = this.merchantQidParam
      this.amountNumber = this.amountNumberParam
      this.httpService = this.$http
    },
    data() {
      return {
        requiredSignup: false,
        showCC: true,
        showCheck: true,
        selectedCC: false,
        selectedCheck: false,
        selectedKlarna: false,
        selectedPaypal: false,
        english: true
      }
    },
    methods: {
      showOnePaymentOptionForm() {
        return this.allowedByMerchantPayments.length == 1
      },
      allowed(type) {
        return this.allowedByMerchantPayments.includes(type)
      },
      available(type) {
        return this.availableForMerchantPaymentMethods.includes(type)
      },
      updateShowCC(value) {
        this.showCC = value
      },
      changeLocale(event) {
        if(event.srcElement.checked) {
          this.$i18n.locale = "en"
        } else {
          this.$i18n.locale = "es"
        }
      }
    },
    computed: {
      isProcessible() {
        if (this.state === "new") { return true } else { return false }
      }
    }
  }
</script>
<style>
</style>
