<template>
  <div>
    <center v-show='tipsLoading' class='grey-text'>
      <i class="fas fa-spinner fa-spin grey-text"></i> 
      Loading ...
    </center>

    <section v-show="!tipsLoading">
      <div v-show="presetTip">
        <section v-if="tipsByAmount" class='tips-row'>
          <span class='tips-btn tips-col' :class="{'active': tipCustomAmount == 0}" @click='setTip(0)'>No tip</span>
          <span class='tips-btn tips-col' :class="{'active': tipCustomAmount == tip1}" @click='setTipAmount(tip1)'>{{tip1 | currency}}</span>
          <span class='tips-btn tips-col' :class="{'active': tipCustomAmount == tip2}" @click='setTipAmount(tip2)'>{{tip2 | currency}}</span>
          <span class='tips-btn tips-col' :class="{'active': tipCustomAmount == tip3}" @click='setTipAmount(tip3)'>{{tip3 | currency}}</span>
        </section>
        <section v-else class='tips-row'>
          <span class='tips-btn tips-col' :class="{'active': selectedTip == 0}" @click='setTip(0)'>No tip</span>
          <span class='tips-btn tips-col' :class="{'active': selectedTip == tip1}" @click='setTip(tip1)'>{{tip1}}%</span>
          <span class='tips-btn tips-col' :class="{'active': selectedTip == tip2}" @click='setTip(tip2)'>{{tip2}}%</span>
          <span class='tips-btn tips-col' :class="{'active': selectedTip == tip3}" @click='setTip(tip3)'>{{tip3}}%</span>
        </section>
      </div>

      <div v-show='!presetTip'>
        <input 

            name='tipCustomAmount'
            ref="customTipInput"
            step="0.01"
            type="number"
            pattern="\d*"
            inputmode="decimal"
            v-validate="'min_value:0.01|required'"
            class='inputText'
            lang="en-001"
            v-model='tipCustomAmount'
            @keyup="tipsUpdated(selectedTip, tipCustomAmount)">

            <span class='red-text small-font' v-show='errors.first("tipCustomAmount")'>Must be 0.01 or more.</span>
      </div>
      <div class='tips-row mt-5'>
        <div class='btn tips-col' @click='enableCustomTip'>{{tipSwitchLabel}}</div>
      </div>
    </section>
  </div>
</template>
<script>
  export default {
    props: ["qid", "tipsUpdated"],
    created() {
      this.loadTips()
    },
    data() {
      return {
        selectedTip: 0,
        tipCustomAmount: 0,
        tipsLoading: true,
        tip1: 5,
        tip2: 10,
        tip3: 15,
        presetTip: true,
        tipsByAmount: false
      }
    },
    methods: {
      enableCustomTip() {
        this.selectedTip = 0
        this.tipCustomAmount = 0
        this.presetTip = !this.presetTip
        this.tipsUpdated(this.selectedTip, this.tipCustomAmount)
        if(!this.presetTip) {
          this.tipCustomAmount = null
          this.$nextTick(() => {
            this.$refs.customTipInput.focus()
          })
        }
      },
      setTip(tip) {
        this.tipCustomAmount = 0
        this.selectedTip = tip
        this.tipsUpdated(this.selectedTip, this.tipCustomAmount)
      },
      setTipAmount(tip) {
        this.tipCustomAmount = tip
        this.selectedTip = 0
        this.tipsUpdated(this.selectedTip, this.tipCustomAmount)
      },
      loadTips() {
        this.$http.get("/api/v1/vue/tip_values", {params: {qid: this.qid}}).then(response => {
          this.tip1 = response.body.tip1
          this.tip2 = response.body.tip2
          this.tip3 = response.body.tip3
          this.tipsByAmount = response.body.tips_by_amount
          if(this.tipsByAmount) {
            this.tipCustomAmount = response.body.default
          } else {
            this.selectedTip = response.body.default
          }
          this.tipsLoading = false
          this.tipsUpdated(this.selectedTip, this.tipCustomAmount)
        }, error => {
          // Fallback to defualt tips
          this.tipsLoading = false
          this.tipsUpdated(this.selectedTip, this.tipCustomAmount)
        })
      },
    },
    computed: {
      tipSwitchLabel() {
        return this.presetTip ? "Custom tip" : "Go back"
      }
    }
  }
</script>
<style scoped>
  .active {
    background-color: #fff;
    border: 2px solid  #00ab8e;
    color: #00ab8e;
  }
</style>
