export default {
  payment: "Payment",
  to: "to",
  changeLanguage: "Cambiar el idioma",
  checkByPhoto: "Check by photo",
  replace: "Replace",
  pleaseConfirmCheckAmount: "Please confirm check amount is equal to {amount} and submit",
  confirmCheckAmountAndSubmit: "Confirm {amount} & submit",
  submittedSuccessfully: "Submitted successully",
  // Pay by card
  payWithCard: "Pay with Credit Card",
  fillInCardDetails: "Fill in card details",
  pleaseSignHere: "Please sign here",
  pleaseSignHereAutoSubmit: "Please sign here (will submit the form)",
  noTip: "No tip",
  agreePayAboveAmount: "I agree to pay the above amount",
  agreePayAboveAmountFirst: "I agree with",
  agreePayAboveAmountUrl: "company policy",
  agreePayAboveAmountLast: "and to pay the above amount, which is non-refundable",
  grossAmount: "Gross amount",
  nonCashAdj: "Non-cash adj",
  pay: "Pay",
  processing: "Processing...",
  loading: "Loading...",
  couldNotProcess: "You could not process payment for this order",
  noPaymentMethod: "Please reach out to the merchant. No active payment methods available",
  buyNowPayLaterButton: "Buy now pay later (Klarna)",
  withTipAmount: "Amount with tip"
}
