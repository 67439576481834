<!-- Simple send qrcodelink without amount and cc/ach option -->
<template>
  <div>
    <!-- Modal Trigger -->
    <a class="waves-effect waves-light btn-large modal-trigger" href="#modal1">
      <i class="far fa-paper-plane"></i> 
      Send Payment Link
    </a>

    <!-- Modal Structure -->
    <div id="modal1" class="modal" ref='tabs'>
      <div class="modal-content">
        <h3>
          Send Payment Link 
        </h3>
      </div>
      <div class="center container small-font">
        <span
          class='hide-on-small-only'
          v-clipboard:success="onCopy"
          v-clipboard:error="onError"
          v-clipboard:copy="paymentLink">

          Your payment link:<br>
          {{paymentLink}}
          <a><i class="far fa-copy"></i></a>
        </span>

        <button class='btn-flat hide-on-med-and-up'
          v-clipboard:success="onCopy"
          v-clipboard:error="onError"
          v-clipboard:copy="paymentLink">

          <i class="far fa-copy"></i> COPY LINK TO CLIPBOARD
        </button>

        <section class='contianer mt-10'>
          <vue-tel-input
            v-model="phone"
            v-show="usePhone"
            :onlyCountries="['US']"
            @validate="phoneValidate"
            wrapperClasses="customPhoneInput"
            placeholder="Cardholder's phone number"></vue-tel-input>

          <input v-show='!usePhone' v-model='email' type='email' placeholder="Cardholder's email" @input='validateEmail'>
        </section>
        <section class='mt-10'>
          <a @click="changeInput" class="btn-large btn-flat grey-text">
            <i class="far fa-envelope grey-text" v-show='usePhone'></i> 
            <i class="fas fa-sms grey-text" v-show='!usePhone'></i> 
            {{useLabel}}
          </a>

          <button :disabled='sendDisabled' href="#!" class="modal-close waves-effect waves-green btn-large" @click='send'>
            <i class="far fa-paper-plane"></i> 
            Send
          </button>
        </section>
        <br><br>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    props: ['paymentLink'],
    data() {
      return {
        phone: null,
        email: null,
        usePhone: true,
        sendDisabled: true
      }
    },
    methods: {
      send() {
        this.$http.post("/api/v1/app/merchant/send_payment_links", this.params).then(response => {
          this.notifySuccess("Link successfully sent")
        }, error => {
          this.notifyError(error.body.error_messages[0])
        })
      },
      phoneValidate(result) {
        if(result.isValid) {
          this.sendDisabled = false
        }
      },
      changeInput() {
        this.phone = null
        this.email = null
        this.sendDisabled = true
        this.usePhone = !this.usePhone
      },
      onCopy() {
        this.notifySuccess('Copied to clipboard!')
      },
      onError() {
        this.notifyError('Unable to copy')
      },
      validateEmail() {
        var emailReg = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        if(emailReg.test(this.email)) {
          this.sendDisabled = false
        } else {
          this.sendDisabled = true
        }
      }
    },
    computed: {
      useLabel() {
        if(this.usePhone) {
          return "Use email"
        } else {
          return "Use phone"
        }
      },
      params() {
        return {
          email: this.email,
          phone: this.phone
        }
      }
    }
  }
</script>
<style>
</style>
